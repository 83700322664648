import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import TeamCard from "../components/team-card"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export default function GirlsSection() {
  return ( 
    <Layout>

      <section className="container">

      <h1>Girls Teams</h1>
    
        <p>Below is a list of our current girls teams. If your child is interested in joining please email the team contact or use our <Link to="/contact">contact</Link> form.</p>
        <p>All coaches are FA qualified. In additional all staff are CRC with at least one First Aid qualified member present at all times.</p>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">

          <div className="col mt-4">
            <TeamCard teamName="Under 8s" image="/team-placeholder.jpg" age="School Year 3" ageInfo="DoB 01/09/2015 - 31/08/2016" league="West Riding Girls" contact="Mike Inman" contactEmail="mailto:leedscityjnrs@gmail.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 10s" image="/girls-u9.jpg" age="School Year 5" ageInfo="DoB 01/09/2014 - 31/08/2015" league="West Riding Girls" contact="Emma Wilkinson" contactEmail="mailto:leedscityjnrs@gmail.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 11s" image="/team-placeholder.jpg" age="School Year 6" ageInfo="DoB 01/09/2012 - 31/08/2013" league="West Riding Girls" contact="Mike Inman" contactEmail="mailto:leedscityjnrs@gmail.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 12s" image="/girls-u11.jpg" age="School Year 7" ageInfo="DoB 01/09/2012 - 31/08/2013" league="West Riding Girls" contact="Mike Inman" contactEmail="mailto:leedscityjnrs@gmail.com" />
          </div>

        </div>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">

        <div className="col mt-4">
            <TeamCard teamName="Under 13s" image="/team-placeholder.jpg" age="School Year 8" ageInfo="DoB 01/09/2011 - 31/08/2012" league="West Riding Girls" contact="Jodi Walsh" contactEmail="mailto:jodi.walsh@hotmail.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 15s" image="/girls-u14.jpg" age="School Year 10" ageInfo="DoB 01/09/2009 - 31/08/2010" league="West Riding Girls" contact="Kaye Longbottom" contactEmail="mailto:carebear.01@hotmail.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 16s" image="/girls-u15.jpg" age="School Year 11" ageInfo="DoB 01/09/2009 - 31/08/2009" league="West Riding Girls" contact="Mick Ward" contactEmail="mailto:rebeccac.13@btinternet.com" />
          </div>
          
          <div className="col mt-4">
            <TeamCard teamName="Under 18s" image="/girls-u17.jpg" age="School Year 12/13" ageInfo="DoB 01/09/2006 - 31/08/2008" league="West Riding Girls" contact="Paul Bell" contactEmail="mailto:paul@peakbell.com" />
          </div>

        </div>

      </section>

    </Layout>
  );
}

